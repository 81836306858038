import { render } from "./DashboardPreviewBanner.vue?vue&type=template&id=5bbaab6b&scoped=true"
import script from "./DashboardPreviewBanner.vue?vue&type=script&lang=js"
export * from "./DashboardPreviewBanner.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-5bbaab6b"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5bbaab6b"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5bbaab6b', script)) {
    api.reload('5bbaab6b', script)
  }
  
  module.hot.accept("./DashboardPreviewBanner.vue?vue&type=template&id=5bbaab6b&scoped=true", () => {
    api.rerender('5bbaab6b', render)
  })

}

script.__file = "src/components/dashboard/DashboardPreviewBanner.vue"

export default script